import React from 'react'
import * as styles from "./404.module.scss"

export default function PageNotFound() {
    const text = `Oops... Page Not Found ¯\\_(ツ)_/¯`
    return (
        <p className={styles.not_found}>
            {text}
        </p>
    )
}
